.alert__data {
  &-wrapper {
    width: 100%;
    height: 100px;
    display: flex;
    flex-wrap: wrap;
    overflow-y: auto;
  }
  &-primary {
    color: #253b78;
    border-color: #e2e8f9;
    background-color: #e2e8f9;
    border-radius: 0;
  }
}

.alert__popup {
  &--primary {
    background-color: $blue-20;
    border-color: $blue-20;
    border-radius: 0;
    &-span {
      font-size: 16px;
      font-weight: 500;
      color: $blue;
      b {
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }
  &--red {
    background-color: #e5acac;
    border-color: #e5acac;
    border-radius: 0;
    &-span {
      font-size: 16px;
      font-weight: 500;
      color: $red;
    }
  }
  &--green {
    background-color: #9adfca;
    border-color: #9adfca;
    border-radius: 0;
    &-span {
      font-size: 16px;
      font-weight: 500;
      color: $green;
    }
  }
  &--orange {
    background-color: #fce799;
    border-color: #fce799;
    border-radius: 0;
    &-span {
      font-size: 16px;
      font-weight: 500;
      color: $yellow;
    }
  }
}

.Toastify__toast-body {
  font-size: 1.25em;
  padding: 0.5em;
}
